<template>
    <div class="audience" v-if="translates && translates[langUrl]">
        <template v-if="loaded">
            <div class="audience__canRequest" v-if="canRequest">
                <BaseButton
                    class="button-fill"
                    @click="goTo('trips-request')">
                    {{ translates[langUrl].button_tripsRequest[lang] }}
                </BaseButton>
            </div>
            <div class="audience__needAnswers" v-else>
                <img src="../../assets/Lightning.svg" class="audience__needAnswers__lightning"/>
                <div class="audience__needAnswers__text">
                    {{ translates[langUrl].needAnswers_1[lang] }} {{ qualifiedQuizCount }} {{ translates[langUrl].needAnswers_2[lang] }}
                </div>
            </div>
        </template>

        <div class="audience__survey">
            <div class="audience__survey__titleContainer">
                <div class="audience__survey__title">
                    {{ translates[langUrl].survey_title[lang] }}
                    <v-tooltip bottom class="audience__survey__tooltip">
                        <template v-slot:activator="{ on, attrs }">
                            <img v-bind="attrs" v-on="on" src="../../assets/info.svg">
                        </template>
                        <span>{{ translates[langUrl].survey_tooltip[lang] }}</span>
                    </v-tooltip>
                </div>
                <div class="audience__survey__previewButton" @click="preview">
                    <img class="audience__survey__previewButton__eye" src="../../assets/Eye.svg" /> 
                    <div class="audience__survey__previewButton__text">{{ translates[langUrl].button_preview[lang] }}</div>
                </div>
            </div>
            <div class="audience__survey__mainContainer">
                <div class="audience__survey__mainContainer__step">
                    <div class="audience__survey__mainContainer__step__number">01</div>
                    <div class="audience__survey__mainContainer__step__title">
                        {{ translates[langUrl].survey_step_first_title[lang] }}
                    </div>
                    <BaseSelect
                        v-if="this.lang !== 'ru'"
                        v-model="quizLanguale"
                        :items="[
                            {label: 'English', value: 'English' },
                            {label: 'Spanish', value: 'Spanish' },
                            {label: 'Russian', value: 'Russian' },
                            {label: 'Hindi', value: 'Hindi' },
                            {label: 'Korean', value: 'Korean' }
                        ]"
                        item-text="label"
                        item-value="value"
                    />
                    <!-- <v-radio-group v-if="this.lang !== 'ru'" v-model="quizLanguale">
                        <AudienceRadio label="English" value="English" style="margin-right: 3px"/>
                        <AudienceRadio label="Spanish" value="Spanish" style="margin-right: 3px"/>
                        <AudienceRadio label="Russian" value="Russian" />
                    </v-radio-group> -->
                    <template v-if="quizLink">
                        <div class="audience__survey__mainContainer__step__link">
                            {{ quizLanguale === 'English'? quizLink : ''  }}
                            {{ quizLanguale === 'Spanish'? quizLinkSpanish : ''  }}
                            {{ quizLanguale === 'Russian'? quizLinkRussian : ''  }}
                            {{ quizLanguale === 'Hindi'? quizLinkHindi : ''  }}
                            {{ quizLanguale === 'Korean'? quizLinkKorean : ''  }}
                        </div>
                        <div class="audience__survey__mainContainer__step__switcher">
                            <div class="audience__survey__mainContainer__step__switcher__title">{{ translates[langUrl].survey_step_first_customQuiz[lang] }}</div>
                            <v-switch style="margin-top: 0;"
                                :input-value="customQuiz"
                                @change="changeCustomisation"
                                hide-details
                            />
                        </div>
                        <BaseButton
                            class="audience__survey__mainContainer__step__button button-fill"
                            @click="linkCopy">
                            <img src="../../assets/copy.svg">
                            {{ translates[langUrl].button_linkCopy[lang] }}
                        </BaseButton>
                    </template>
                </div>
                <div class="audience__survey__mainContainer__arrow"></div>
                <div class="audience__survey__mainContainer__step">
                    <div class="audience__survey__mainContainer__step__number">02</div>
                    <div class="audience__survey__mainContainer__step__title">
                        {{ translates[langUrl].survey_step_second_title[lang] }}
                    </div>
                    <div class="audience__survey__mainContainer__step__description">
                        {{ translates[langUrl].survey_step_second_description[lang] }}
                    </div>
                </div>
                <div class="audience__survey__mainContainer__arrow"></div>
                <div class="audience__survey__mainContainer__step">
                    <div class="audience__survey__mainContainer__step__number">03</div>
                    <div class="audience__survey__mainContainer__step__title">
                        {{ translates[langUrl].survey_step_third_title[lang] }}
                    </div>
                    <div class="audience__survey__mainContainer__step__description">
                        {{ translates[langUrl].survey_step_third_description[lang] }}
                    </div>
                </div>
            </div>
        </div>

        <div class="audience__quiz-results">
            <div class="audience__quiz-results__row">
                <div class="audience__quiz-results__title">
                    {{ translates[langUrl].quiz_results[lang] }}
                </div>
                <!-- <BaseButton
                    class="button-fill"
                    v-if="canRequest"
                    @click="goTo('trips-request')">
                    {{ translates[langUrl].button_tripsRequest[lang] }}
                </BaseButton> -->
                <div class="audience__quiz-results__switch">
                    <span v-html="translates[langUrl].quiz_onlyQualified[lang]"></span>
                    <v-switch
                        :input-value="onlyQualified"
                        @change="showOlyQualified"
                        hide-details
                    />
                </div>
            </div>
            <div class="audience__quiz-results__row">
                <div class="audience__quiz-results__summary">
                    {{ translates[langUrl].quiz_count[lang] }}
                    <span>{{ summary.count > 0 ? summary.count : '-' }}</span>
                </div>
                <div class="audience__quiz-results__summary">
                    {{ translates[langUrl].quiz_qualified[lang] }}
                    <span>{{ summary.qualified > 0 ? summary.qualified : '-' }}</span>
                </div>
                <div class="audience__quiz-results__summary">
                    {{ translates[langUrl].quiz_direction[lang] }}
                    <span>{{ summary.direction }}</span>
                </div>
                <div class="audience__quiz-results__summary">
                    {{ translates[langUrl].quiz_average_price[lang] }}
                    <span>{{ summary.averagePrice }} USD</span>
                </div>
            </div>
            <div class="audience__quiz-results__row">
                <div class="audience__quiz-results__col">
                    <div class="audience__quiz-results__block">
                        <div class="audience__quiz-results__block__title">
                            {{ translates[langUrl].quiz_directions[lang] }}
                        </div>
                        <template v-if="summary.count > 0 && summary.directions.length">
                            <div class="audience__quiz-results__block__items">
                                <template v-for="(item, index) in summary.directions">
                                    <div :key="`direction-${index}`" class="audience__quiz-results__block__direction">
                                        <span>{{ item.title }}</span>
                                        {{ item.value || 0 }}
                                    </div>
                                </template>
                            </div>
                        </template>
                        <div v-else class="audience__quiz-results__block__empty">
                            {{ translates[langUrl].quiz_empty[lang] }}
                        </div>
                    </div>
                    <div class="audience__quiz-results__block">
                        <div class="audience__quiz-results__block__title">
                            {{ translates[langUrl].quiz_prices[lang] }}
                        </div>
                        <template v-if="summary.count > 0 && summary.prices.length">
                            <div class="audience__quiz-results__block__items">
                                <template v-for="(item, index) in summary.prices">
                                    <div :key="`price-${index}`" class="audience__quiz-results__block__price">
                                        <span>{{ item.title }}</span>
                                        {{ item.value || 0 }}
                                    </div>
                                </template>
                            </div>
                        </template>
                        <div v-else class="audience__quiz-results__block__empty">
                            {{ translates[langUrl].quiz_needMoreValue[lang] }}
                        </div>
                    </div>
                    <div class="audience__quiz-results__block">
                        <div class="audience__quiz-results__block__title">
                            {{ translates[langUrl].quiz_comforts[lang] }}
                        </div>
                        <template v-if="summary.count > 0 && summary.comforts.length">
                            <div class="audience__quiz-results__block__items">
                                <template v-for="(item, index) in summary.comforts">
                                    <div :key="`direction-${index}`" class="audience__quiz-results__block__direction">
                                        <span>{{ item.title }}</span>
                                        {{ item.value || 0 }}
                                    </div>
                                </template>
                            </div>
                        </template>
                        <div v-else class="audience__quiz-results__block__empty">
                            {{ translates[langUrl].quiz_empty[lang] }}
                        </div>
                    </div>
                    <div class="audience__quiz-results__block">
                        <div class="audience__quiz-results__block__title">
                            {{ translates[langUrl].quiz_ages[lang] }}
                        </div>
                        <template v-if="summary.count > 0 && summary.ages.length">
                            <div class="audience__quiz-results__block__items">
                                <template v-for="(item, index) in summary.ages">
                                    <div :key="`age-${index}`" class="audience__quiz-results__block__age">
                                        <span>{{ item.title }}</span>
                                        {{ item.value || 0 }}
                                    </div>
                                </template>
                            </div>
                        </template>
                        <div v-else class="audience__quiz-results__block__empty">
                            {{ translates[langUrl].quiz_needMoreValue[lang] }}
                        </div>
                    </div>
                </div>
                <div class="audience__quiz-results__col">
                    <div class="audience__quiz-results__block">
                        <div class="audience__quiz-results__block__title">
                            {{ translates[langUrl].quiz_activities[lang] }}
                        </div>
                        <template v-if="summary.count > 0 && summary.activities.length">
                            <div class="audience__quiz-results__block__items">
                                <template v-for="(item, index) in summary.activities.slice(0,6)">
                                    <div :key="`direction-${index}`" class="audience__quiz-results__block__direction">
                                        <span>{{ item.title }}</span>
                                        {{ item.value || 0 }}
                                    </div>
                                </template>
                            </div>
                        </template>
                        <div v-else class="audience__quiz-results__block__empty">
                            {{ translates[langUrl].quiz_empty[lang] }}
                        </div>
                    </div>
                    <div class="audience__quiz-results__block">
                        <div class="audience__quiz-results__block__title" v-if="translates[langUrl].quiz_durations[lang]">
                            {{ translates[langUrl].quiz_durations[lang] }}
                        </div>
                        <template v-if="summary.count > 0 && summary.durations.length">
                            <div class="audience__quiz-results__block__items">
                                <template v-for="(item, index) in summary.durations">
                                    <div :key="`direction-${index}`" class="audience__quiz-results__block__direction">
                                        <span>{{ item.title }}</span>
                                        {{ item.value || 0 }}
                                    </div>
                                </template>
                            </div>
                        </template>
                        <div v-else class="audience__quiz-results__block__empty">
                            {{ translates[langUrl].quiz_empty[lang] }}
                        </div>
                    </div>
                    <!-- <div class="audience__quiz-results__block">
                        <div class="audience__quiz-results__block__title" v-if="translates[langUrl].quiz_tripsCounts[lang]">
                            {{ translates[langUrl].quiz_tripsCounts[lang] }}
                        </div>
                        <template v-if="summary.count > 0 && summary.tripsCounts.length">
                            <div class="audience__quiz-results__block__items">
                                <template v-for="(item, index) in summary.tripsCounts">
                                    <div :key="`direction-${index}`" class="audience__quiz-results__block__direction">
                                        <span>{{ item.title }}</span>
                                        {{ item.value || 0 }}
                                    </div>
                                </template>
                            </div>
                        </template>
                        <div v-else class="audience__quiz-results__block__empty">
                            {{ translates[langUrl].quiz_empty[lang] }}
                        </div>
                    </div> -->
                    <div class="audience__quiz-results__block">
                        <div class="audience__quiz-results__block__title">
                            {{ translates[langUrl].quiz_months[lang] }}
                        </div>
                        <template v-if="summary.count > 0 && summary.months.length">
                            <div class="audience__quiz-results__block__items">
                                <template v-for="(item, index) in summary.months">
                                    <div :key="`month-${index}`" class="audience__quiz-results__block__month">
                                        <span><span></span> {{ item.title }}</span>
                                        {{ item.value || 0 }}
                                    </div>
                                </template>
                            </div>
                        </template>
                        <div v-else class="audience__quiz-results__block__empty">
                            {{ translates[langUrl].quiz_needMoreValue[lang] }}
                        </div>
                    </div>
                    <div class="audience__quiz-results__block">
                        <div class="audience__quiz-results__block__title">
                            {{ translates[langUrl].quiz_sexes[lang] }}
                        </div>
                        <template v-if="summary.count > 0 && summary.sexes.length">
                            <div class="audience__quiz-results__block__items">
                                <template v-for="(item, index) in summary.sexes">
                                    <div :key="`sex-${index}`" class="audience__quiz-results__block__sex">
                                        <span><span></span> {{ item.title }}</span>
                                        {{ item.value || 0 }}
                                    </div>
                                </template>
                            </div>
                        </template>
                        <div v-else class="audience__quiz-results__block__empty">
                            {{ translates[langUrl].quiz_needMoreValue[lang] }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="audience__waiting-list">
            <div class="audience__waiting-list__row">
                <div class="audience__waiting-list__title">
                    {{ translates[langUrl].address_list[lang] }}
                </div>
                <div class="audience__waiting-list__switch">
                    <span v-html="translates[langUrl].address_conter[lang]"></span>
                    {{ quizes.length }}
                </div>
            </div>
            <div class="audience__waiting-list__header">
                <div class="audience__waiting-list__header__item name">
                    {{ translates[langUrl].waitingList_name[lang] }}
                </div>
                <div class="audience__waiting-list__header__item name">
                    {{ translates[langUrl].waitingList_profession[lang] }}
                </div>
                <div class="audience__waiting-list__header__item email">
                    {{ translates[langUrl].waitingList_email[lang] }}
                </div>
                <div class="audience__waiting-list__header__item phone">
                    {{ translates[langUrl].waitingList_phone[lang] }}
                </div>
                <div class="audience__waiting-list__header__item date">
                    {{ translates[langUrl].waitingList_date[lang] }}
                </div>
            </div>
            <template v-for="(contacts, index) in quizes">
                <div :key="'contacts' + index" class="audience__waiting-list__item">
                    <div class="audience__waiting-list__item__row">
                        <div class="audience__waiting-list__item__name">
                            <div class="audience__waiting-list__item__textfieldName">{{ translates[langUrl].waitingList_name[lang] }}:</div> {{ contacts.userName }}
                        </div>
                        <div class="audience__waiting-list__item__name">
                            <div class="audience__waiting-list__item__textfieldName">{{ translates[langUrl].waitingList_profession[lang] }}:</div> {{ contacts.userProfession }}
                        </div>
                        <div class="audience__waiting-list__item__email">
                            <div class="audience__waiting-list__item__textfieldName">{{ translates[langUrl].waitingList_email[lang] }}:</div> {{ contacts.userEmail }}
                        </div>
                        <div class="audience__waiting-list__item__phone">
                            <div class="audience__waiting-list__item__textfieldName">{{ translates[langUrl].waitingList_phone[lang] }}:</div> {{ contacts.userPhone }}
                        </div>
                        <div class="audience__waiting-list__item__date">
                            <div class="audience__waiting-list__item__textfieldName">{{ translates[langUrl].waitingList_date[lang] }}:</div> {{ dateFormatted(contacts.createdAt) }}
                        </div>
                    </div>
                    <div class="audience__waiting-list__item__row audience__waiting-list__item__row__start">
                        <div class="audience__waiting-list__item__title">{{ translates[langUrl].waitingList_location[lang] }}:</div> 
                        <div class="audience__waiting-list__item__value">
                            {{ contacts.userCountry }}{{ contacts.userState ? ', ' + contacts.userState : '' }}{{ contacts.userCity ? ', ' + contacts.userCity : '' }}
                        </div>
                    </div>
                    <div v-if="contacts.experience" class="audience__waiting-list__item__row audience__waiting-list__item__row__start">
                        <div class="audience__waiting-list__item__title">{{ translates[langUrl].waitingList_experience[lang] }}:</div> 
                        <div class="audience__waiting-list__item__value">{{ contacts.experience }}</div>
                    </div>
                    <div v-if="contacts.ideas" class="audience__waiting-list__item__row audience__waiting-list__item__row__start">
                        <div class="audience__waiting-list__item__title">{{ translates[langUrl].waitingList_ideas[lang] }}:</div> 
                        <div class="audience__waiting-list__item__value">{{ contacts.ideas }}</div>
                    </div>
                </div>
            </template>
        </div>

        <v-dialog
            v-model="popuplinkError"
            max-width="500px"
        >
            <div class="quiz__popup">
                <div class="quiz__popup__title">{{ translates[langUrl].linkError_title[lang] }}</div>
                <div class="quiz__popup__close" @click="openLinkError"></div>
                <div class="quiz__popup__text">{{ translates[langUrl].linkError_text[lang] }}</div>
            </div>
        </v-dialog>
        <!-- <PopupRequest
            v-model="popupRequest"
            :name="user.firstname"
            @close="popupRequest = false"
            @create="goTo('trips-request')"
        ></PopupRequest> -->
    </div>
</template>

<script>
    import store from '@/store';
    import moment from '@/plugins/moment'
    import { mapState } from 'vuex';
    import { isMobile } from '@/helpers';

    import BaseButton from '../../components/common/BaseButton.vue';
    import BaseAlertDialog from '../../components/common/BaseAlertDialog.vue';
    import PopupRequest from '../../components/common/PopupRequest.vue';
    import AudienceRadio from '../../components/common/AudienceRadio.vue';
    import BaseSelect from '../../components/common/BaseSelect.vue';

    const QUALIFIED_COUNT = 50;
    
    export default {
        name: 'Audience',
        metaInfo() {
            return {
                title: this.translates[this.langUrl] ? this.translates[this.langUrl].browserTitle[this.lang] : ''
            }
        },
        components: {
            BaseButton,
            BaseAlertDialog,
            PopupRequest,
            AudienceRadio,
            BaseSelect
        },
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('quiz', ['summary']),
            ...mapState('quiz', {
                quizes: state => state.entities
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            quizLink() {
                return `${process.env.VUE_APP_CURRENT_URL}/quiz/${this.customQuizValue}/${this.user.social || null}`;
            },
            quizLinkSpanish() {
                return `${process.env.VUE_APP_CURRENT_URL}/quiz/spanish/${this.customQuizValue}/${this.user.social || null}`;
            },
            quizLinkRussian() {
                return `${process.env.VUE_APP_CURRENT_URL}/quiz/russian/${this.customQuizValue}/${this.user.social || null}`;
            },
            quizLinkHindi() {
                return `${process.env.VUE_APP_CURRENT_URL}/quiz/hindi/${this.customQuizValue}/${this.user.social || null}`;
            },
            quizLinkKorean() {
                return `${process.env.VUE_APP_CURRENT_URL}/quiz/korean/${this.customQuizValue}/${this.user.social || null}`;
            },
            qualifiedQuizCount() {
                return this.user.qualifiedQuizCount || QUALIFIED_COUNT;
            }
        },
        data: () => ({
            isMobile,
            isDesktop: false,
            loaded: false,
            onlyQualified: false,
            canRequest: false,
            popupRequest: false,
            popuplinkError: false,
            quizLanguale: 'English',
            customQuiz: false,
            customQuizValue: 'common',
            langUrl: '/blogger/audience'
        }),
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        async mounted() {
            this.$root.$emit('preloaderShow');
            await store.dispatch('auth/fetch'); 
            await this.getSummary();
            await this.getQuizes();
            if(this.user.blogger && this.summary.qualified >= (this.user.qualifiedQuizCount || QUALIFIED_COUNT)) {
                this.canRequest = true;
                // if(!localStorage.getItem('hideTripRequestPopup')) {
                //     this.popupRequest = true;
                // }
            }
            this.loaded = true;
            this.$root.$emit('preloaderHide');
        },
        methods: {
            async goTo(name) {
                if(name === 'trips-request') {
                    localStorage.setItem('hideTripRequestPopup', true);
                }
                await this.$router.push({ name });
            },
            async getSummary() {
                await store.dispatch('quiz/summary', { qualified: this.onlyQualified });
            },
            async getQuizes() {
                await store.dispatch('quiz/fetch', {});
            },
            onResize() {
                this.isDesktop = window.innerWidth >= 768;
            },
            async linkCopy() {
                if(this.user.social) {
                    if(this.lang === 'en') {
                        this.quizLanguale === 'English' ? navigator.clipboard.writeText(this.quizLink) : ''
                        this.quizLanguale === 'Spanish' ? navigator.clipboard.writeText(this.quizLinkSpanish) : ''
                        this.quizLanguale === 'Russian' ? navigator.clipboard.writeText(this.quizLinkRussian) : ''
                        this.quizLanguale === 'Hindi' ? navigator.clipboard.writeText(this.quizLinkHindi) : ''
                        this.quizLanguale === 'Korean' ? navigator.clipboard.writeText(this.quizLinkKorean) : ''
                    } else {
                        navigator.clipboard.writeText(this.quizLink);
                    }
                } else {
                    openLinkError();
                }
            },
            async preview() {
                window.open(this.quizLink, '_blank');
            },
            async showOlyQualified(value) {
                this.onlyQualified = value;
                await this.getSummary();
            },
            dateFormatted(date) {
                return moment(date).format('L')
            },
            openLinkError() {
                this.popuplinkError = !this.popuplinkError
            },
            changeCustomisation(value) {
                this.customQuiz = value
                if(this.customQuiz === true) {
                    this.customQuizValue = 'blogger'
                } else {
                    this.customQuizValue = 'common'
                }
            }
        },
    };
</script>

<style lang="scss">
.audience {
    &__header {
        position: relative;
        width: 100%;
        height: 240px;
        background: url('../../assets/bloggers/audience-bg.png') center center no-repeat;
        background-size: cover;
        border-radius: 0px 0px 12px 12px;
        overflow: hidden;

        @media all and (max-width: 768px) {
            display: flex;
            justify-content: center;
            align-items: center;
            background: url('../../assets/bloggers/audience-bg-mobile.png') center center no-repeat;
            background-size: cover;
        }

        &__title {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 40px;
            font-weight: 700;
            font-size: 38px;
            line-height: 46px;
            color: #FFFFFF;
            
            @media all and (max-width: 768px) {
                position: relative;
                font-weight: 800;
                font-size: 24px;
                line-height: 150%;
                text-align: center;
                letter-spacing: -0.02em;
            }
        }
    }

    &__quiz-link {
        height: 125px;
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 12px 20px 20px;
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        color: #FFFFFF;
        background: linear-gradient(270deg, rgba(225, 23, 85, 0.48) 0%, rgba(225, 23, 85, 0) 100%);
            
        @media all and (max-width: 768px) {
            height: 90px;
            padding: 20px;
            position: relative;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            color: #273155;
            background: #FFFFFF;

            .base-button {
                margin-left: 20px;
            }
        }
    }

    &__content {
        padding: 50px 0;
        max-width: 992px;
        margin: 0 auto;

        @media all and (max-width: 768px) {
            background: #FFFFFF;
            padding: 30px 20px 10px;
        }

        h3 {
            margin: 0;
            padding: 10px 0;
            font-weight: 600;
            font-size: 20px;
            line-height: 140%;
            color: #273155;
            
            @media all and (max-width: 768px) {
                padding: 10px 0;
                font-weight: 700;
                font-size: 28px;
                line-height: 34px;
                color: #273155;
            }
        }
        p {
            margin: 0;
            padding: 10px 0;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: #616D89;
            &.link {
                color: #3107D8;
                text-decoration: underline;
                cursor: pointer;
            }
        }
        &__buttons {
            display: flex;
            flex-direction: row;
            margin-top: 22px;
            @media all and (max-width: 768px) {
                flex-direction: column;
            }
            .button-fill {
                margin-right: 16px;
                @media all and (max-width: 768px) {
                    margin-right: 0;
                    margin-bottom: 16px;
                    width: 100%;
                }
            }
            button {
                @media all and (max-width: 768px) {
                    width: 100%;
                }
            }
        }
    }

    &__canRequest {
        width: 100%;
        height: 52px;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 40px 0 0 0;

        .button-fill {
            width: 100%;
        }
    }

    &__needAnswers {
        width: 100%;
        height: 52px;
        border: 1px #E61E4D solid;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        margin: 40px 0 0 0;
        @media all and (max-width: 768px) {
            height: unset;
            margin: 20px 14px;
            width: calc(100% - 28px);
        }
        &__lightning {
            margin-right: 16px;
        }
        &__text {
            font-weight: 600;
            font-size: 16px;
            line-height: 200%;
            color: #E61E4D;
        }
    }

    &__survey {
        margin: 40px 0 60px 0;
        @media all and (max-width: 768px) {
            margin: 0 14px 40px;
        }

        &__titleContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 20px;
            @media all and (max-width: 768px) {
                margin: 0 14px;
                flex-direction: column;
            }   
        }
        &__title {
            display: flex;
            justify-content: center;
            font-weight: 600;
            font-size: 28px;
            line-height: 140%;
            color: #273155;
            img {
                width: 22px;
                margin-left: 12px;
            }
            @media all and (max-width: 768px) {
                margin-bottom: 20px;
            }
        }
        &__tooltip {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #273155;
            background: #FFFFFF;
            border-radius: 12px;
            border: 1px solid #273155;
        }
        &__previewButton {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 36px;
            padding: 8px 16px;
            border: 1px solid #A1A4B1;
            border-radius: 8px;
            cursor: pointer;

            &__eye {
                width: 20px;
                height: 12px;
                margin-right: 10px;
            }
            &__text {
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                text-align: center;
                color: #A1A4B1;

            }
        }
        &__mainContainer {
            background: #F8F8F8;
            border-radius: 16px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            padding: 40px;
            @media all and (max-width: 768px) {
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            &__step {
                width: 304px;
                overflow: visible;
                position: relative;

                &__title {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 140%;
                    color: #273155;
                    margin-bottom: 5px;
                }

                &__description {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    color: #616D89;
                }

                &__link {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 140%;
                    color: #616D89;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    border-radius: 12px;
                    border: 2px solid #d5dae2;
                    padding: 12px;
                    margin: 15px 0;
                    white-space: nowrap;
                }

                &__switcher {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    height: 50px;
                    &__title {
                        margin-top: 3px;
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 17px;
                        letter-spacing: -0.02em;
                        // color: #9FA5AD;
                    }
                }

                &__button {
                    width: 100%;
                    height: 56px !important;
                    img {
                        margin-right: 12px;
                    }
                }

                &__number {
                    position: absolute;
                    width: 51px;
                    height: 38px;
                    top: -20px;
                    left: -20px;
                    font-weight: 700;
                    font-size: 42px;
                    line-height: 100%;
                    color: #000000;
                    opacity: 0.04;
                }
            }

            &__arrow {
                width: 16px;
                height: 100px;
                background: url('../../assets/ArrowPink.svg') bottom center no-repeat;
                background-size: contain;
                @media all and (max-width: 768px) {
                    transform: rotate(90deg);
                    background-position-y: 50px;
                }
            }
            .v-input--radio-group--column .v-input--radio-group__input {
                display: flex;
                flex-direction: row;
            }
            .v-messages {
                display: none;
            }
        }
    }

    &__quiz-results {
        &__row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0 -16px;
            
            @media all and (max-width: 768px) {
                margin: 0 14px;
            }
        }
        &__col {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: calc(50% - 32px);
            margin: 0 16px;
            
            @media all and (max-width: 768px) {
                width: calc(100% - 12px);
                margin: 0 6px;
            }
        }
        &__title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 16px;
            font-weight: 600;
            font-size: 28px;
            line-height: 140%;
            color: #273155;
            
            @media all and (max-width: 768px) {
                margin: 0 6px 10px;
                font-weight: 600;
                font-size: 16px;
                line-height: 140%;
                color: #273155;
            }
        }
        &__switch {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 16px;
            
            @media all and (max-width: 768px) {
                flex-direction: row-reverse;
                margin: 10px 6px;
            }

            .v-input {
                margin: 0;
                padding: 0;
            }
            & > span {
                margin-right: 12px;
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                text-align: right;
                letter-spacing: -0.02em;
                color: #273155;
            
                @media all and (max-width: 768px) {
                    text-align: left;
                }
            }
        }
        &__summary {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 120px;
            padding: 20px;
            width: calc(25% - 32px);
            margin: 16px;
            background: #FFFFFF;
            border-radius: 12px;
            box-shadow: 0px 12px 12px rgba(44, 47, 56, 0.05);
            
            @media all and (max-width: 768px) {
                width: calc(50% - 12px);
                margin: 6px;
            }

            span {
                font-weight: 600;
                font-size: 20px;
                line-height: 140%;
                color: #273155;
            }
        }
        &__block {
            width: 100%;
            padding: 32px;
            background: #FFFFFF;
            border-radius: 12px;
            margin: 16px 0;
            box-shadow: 0px 12px 12px rgba(44, 47, 56, 0.05);
            
            @media all and (max-width: 768px) {
                margin: 6px 0;
                padding: 20px;
            }

            &__title {
                font-weight: 600;
                font-size: 20px;
                line-height: 140%;
                color: #273155;
                margin-bottom: 24px;
            }
            &__items {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin: 0 -16px;
            
                @media all and (max-width: 768px) {
                    margin: 0 -10px;
                }

                > div {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: calc(50% - 32px);
                    margin: 4px 16px;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    color: #273155;
            
                    @media all and (max-width: 768px) {
                        width: calc(50% - 20px);
                        margin: 4px 10px;
                    }

                    > span {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: #616D89;
                        padding-right: 10px;
                    }

                    > span > span {
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        border-radius: 2px;
                        margin-right: 8px;
                
                        @media all and (max-width: 768px) {
                            margin-right: 2px;
                        }
                    }
                }
            }
            &__direction,
            &__price,
            &__age {
                padding: 8px 12px;
                border: 1px solid #EDEDED;
                border-radius: 12px;
            }
            &__month {
                &:nth-child(12n + 1)  { > span > span { background: #3D9ACC; } }
                &:nth-child(12n + 2)  { > span > span { background: #93CFEF; } }
                &:nth-child(12n + 3)  { > span > span { background: #02BE8A; } }
                &:nth-child(12n + 4)  { > span > span { background: #5EDCB9; } }
                &:nth-child(12n + 5)  { > span > span { background: #BBF5E5; } }
                &:nth-child(12n + 6)  { > span > span { background: #EF4951; } }
                &:nth-child(12n + 7)  { > span > span { background: #FF868B; } }
                &:nth-child(12n + 8)  { > span > span { background: #FFBCBF; } }
                &:nth-child(12n + 9)  { > span > span { background: #FF9518; } }
                &:nth-child(12n + 10) { > span > span { background: #FFBB6A; } }
                &:nth-child(12n + 11) { > span > span { background: #FFBB6A; } }
                &:nth-child(12n)      { > span > span { background: #007DBC; } }
            }
            &__sex {
                &:nth-child(12n + 1)  { > span > span { background: #56A7D3; } }
                &:nth-child(12n + 2)  { > span > span { background: #FF78C1; } }
                &:nth-child(12n + 3)  { > span > span { background: #F1E8B8; } }
            }
            &__empty {
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: #A1A4B1;
            }
        }
    }

    &__waiting-list {
        -ms-user-select: none; 
		-moz-user-select: none; 
		-webkit-user-select: none; 
		user-select: none; 

        &__row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0 -16px;
            
            @media all and (max-width: 768px) {
                margin: 0 14px;
            }
        }
        &__col {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: calc(50% - 32px);
            margin: 0 16px;
            
            @media all and (max-width: 768px) {
                width: calc(100% - 12px);
                margin: 0 6px;
            }
        }
        &__title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 16px;
            font-weight: 600;
            font-size: 28px;
            line-height: 140%;
            color: #273155;
            
            @media all and (max-width: 768px) {
                margin: 0 6px 10px;
                font-weight: 600;
                font-size: 16px;
                line-height: 140%;
                color: #273155;
            }
        }
        &__switch {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 16px;
            
            @media all and (max-width: 768px) {
                margin: 10px 6px;
            }

            & > span {
                margin-right: 12px;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                text-align: right;
                letter-spacing: -0.02em;
                color: #A1A4B1;

                @media all and (max-width: 768px) {
                    text-align: left;
                }
            }
        }
        &__header {
            padding: 8px 32px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 12px;
            @media all and (max-width: 768px) {
                display: none;
            }
            &__item {
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #A1A4B1;

                &.name {
                    width: 30%;
                }
                &.email {
                    width: 30%;
                }
                &.phone {
                    width: 30%;
                }
                &.date {
                    width: 10%;
                }
            }
        }
        &__item {
            padding: 20px 32px;
            background: #FFFFFF;
            box-shadow: 0px 4px 16px rgba(44, 47, 56, 0.05);
            border-radius: 12px;
            margin-bottom: 12px;

            @media all and (max-width: 768px) {
                padding: 15px;
                margin: 0 20px 12px;
                overflow: scroll;
            }

            &__row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                &__start {
                    justify-content: flex-start;
                }

                @media all and (max-width: 768px) {
                    flex-direction: column;
                }
            }
            &:last-of-type {
                margin-bottom: 0;
            }
            &__title {
                color: #A1A4B1;
                padding-right: 10px;
            }
            &__textfieldName {
                margin-right: 5px;
                min-width: 80px;
                color: #A1A4B1;
                @media all and (min-width: 769px) {
                    display: none;
                }
            }
            &__name {
                display: flex;
                width: 30%;
                font-weight: 400;
                font-size: 18px;
                line-height: 180%;
                @media all and (max-width: 768px) {
                    width: 100%;
                }
            }
            &__email {
                display: flex;
                width: 30%;
                font-weight: 600;
                font-size: 18px;
                line-height: 140%;
                @media all and (max-width: 768px) {
                    width: 100%;
                }
            }
            &__phone {
                display: flex;
                width: 30%;
                font-weight: 400;
                font-size: 18px;
                line-height: 180%;
                @media all and (max-width: 768px) {
                    width: 100%;
                }
            }
            &__date {
                display: flex;
                width: 10%;
                font-weight: 400;
                font-size: 18px;
                line-height: 180%;
                @media all and (max-width: 768px) {
                    width: 100%;
                }
            }
        }
    }
}
</style>